<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap";
</style>

<template>
  <section id="app-container">
    <Header />
    <router-view></router-view>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import Header from "./components/Header";
import sassStyles from './App.scss';

export default defineComponent({
  name: "App",
  components: {
    Header,
  },
  sassStyles
});
</script>

<style>
body {
  background: rgb(144, 0, 0, 0.1);
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
</style>
