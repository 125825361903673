<template>
<!--
  <router-link to="/instagram">instagram</router-link>
  -->

  <section class="subheader">
    <p>
      Estamos no ar desde 2008 com dicas de restaurantes, novidades de produtos
      e receitas acessíveis 🍔🍫 Já tivemos um blog colaborativo com mais de
      1000 postagens e hoje concentramos nossas comunicações nas nossas redes
      sociais. Adoramos experimentar comidas diferentes (somos formiguinhas!) e
      ir a restaurantes! E de vez em quando nos aventuramos na cozinha!
    </p>

    <div>
      <SocialMedia />
    </div>
  </section>

  <div class="instagram-grid">
    <div v-for="k in instagramKeys" v-bind:key="k">
      <InstagramEmbed :code="k" />
    </div>
  </div>

</template>

<script>
import InstagramEmbed from "../components/InstagramEmbed";
import SocialMedia from "../components/SocialMedia";
import sassStyles from './Home.scss';
export default {
  name: "Home",
  props: {
    msg: String,
    instagramKeys: Array
  },
  components: {
    InstagramEmbed,
    SocialMedia,
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "//www.instagram.com/embed.js");
    document.head.appendChild(externalScript);
  },
  sassStyles
};
</script>
