import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import Home from "./pages/Home";
import InstagramShowcase from "./pages/InstagramShowcase";

import disneyworld from './configurations/disneyworld.json';

const pascoa = [
  "CM9sr97BGVX",
  "CM70Aphhclz",
  "CM660Pwhntf",
  "CM2psc8BI-p",
  "CM1uxK_BGeH",
  "CMW_M1eBLU0",
  "CMwlF2Ch11m",
  "CMrvischtrW",
  "CMjvPxHBZd7",
  "CMcAj6zhQDf",
  "CMPH11-BhY6",
  "CMMiJBoBxNi",
];

const routes = [
  {
    path: "/",
    component: Home,
    props: { instagramKeys: ["CMjvPxHBZd7", "CMW_M1eBLU0", "CLtnHwphNLC"] },
  },
  {
    path: "/instagram/pascoa2021",
    component: InstagramShowcase,
    props: { instagramKeys: pascoa, title: "Páscoa 2021" },
  },
  {
    path: "/instagram/disney_world",
    component: InstagramShowcase,
    props: { instagramKeys: disneyworld, title: "Disney World" },
  },
  { path: "/instagram", component: InstagramShowcase },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.mount("#app");
