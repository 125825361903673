<template>
  <header>
    <img alt="Eu Comi!" src="../assets/eucomi.png" />
    <h1>Eu Comi!</h1>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {},
};
</script>

<style scoped>
header {
  display: flex;
  flex-direction: column;
}
img {
  width: 20rem;
  margin: 5rem auto 0;
}

h1 {
  display: none;
}

@media screen and (max-width: 576px) {
  h1 {
    display: none;
  }
  img {
    margin-top: 2rem;
  }
}
</style>
