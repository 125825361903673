<template>
  <h3>{{ title }}</h3>
  <div class="instagram-grid">
    <div v-for="k in instagramKeys" v-bind:key="k">
      <InstagramEmbed :code="k" />
    </div>
  </div>
</template>

<script>
import InstagramEmbed from "../components/InstagramEmbed";
export default {
  name: "InstagramShowcase",
  components: {
    InstagramEmbed,
  },
  props: {
    instagramKeys: {
      type: Array,
      default: () => {
        return ["CMjvPxHBZd7", "CMW_M1eBLU0", "CLtnHwphNLC"];
      },
    },
    title: String,
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "//www.instagram.com/embed.js");
    document.head.appendChild(externalScript);
  },
};
</script>

<style scoped>
h3 {
  font-size: 3rem;
  text-align: center;
  margin: 2rem 0 4rem;
}
</style>
