<template>
  <h2>Visite nossas redes sociais</h2>
  <div>
    <a
      href="https://instagram.com/eucomi"
      title="Instagram Eu Comi!"
      target="_blank"
      ><img src="../assets/instagram.png" alt="Ícone Instagram"
    /></a>

    <a
      href="https://facebook.com/eucomi"
      title="Facebook Eu Comi!"
      target="_blank"
      ><img src="../assets/facebook.png" alt="Ícone Facebook"
    /></a>

    <a
      href="https://twitter.com/eucomi"
      title="Twitter Eu Comi!"
      target="_blank"
      ><img src="../assets/twitter.png" alt="Ícone Twitter"
    /></a>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
};
</script>

<style scoped>
div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
h2 {
  font-size: 3rem;
  color: #78a41b;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 1rem;
  text-align: center;
}
a {
  width: 4rem;
  text-align: center;
  margin: 0 1rem;
}
a img {
  width: 4rem;
  height: 4rem;
}
a:hover {
  opacity: 0.6;
}
@media screen and (max-width: 576px) {
  h2 {
    font-size: 2rem;
    margin: 1rem 0 2rem;
  }
}
</style>
